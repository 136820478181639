.top-header {
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  z-index: 1030;
  transition: 0.3s;
}

.header-scrolled {
  background: #bbc5fa;
  transition: 0.3s;
}

.valuta {
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  z-index: 99999;
  background-color: white;
  padding: 10px 0 0 0;
}

.valuta .container {
  display: flex;
  align-items: center;
  justify-content: center;
  column-gap: 100px;
  flex-wrap: wrap;
}

.valuta__first {
  display: flex;
  align-items: center;
  column-gap: 10px;
}

.valuta__last {
  display: flex;
  align-items: center;
  column-gap: 10px;
}

.valuta__first .valuta__content span {
  color: #ec2327 !important;
}

.valuta__last .valuta__content span {
  color: #52ad31 !important;
}

.valuta__content {
  display: flex;
  align-items: center;
  column-gap: 5px;
}

.valuta__content strong {
  font-size: 16px;
  font-weight: 600;
  color: #455a64 !important;
}

.valuta__content span {
  color: #455a64 !important;
  font-size: 16px;
  font-weight: 400;
}

.header {
  padding: 20px 0;
}

.header .container {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.logo img {
  width: 300px;
  height: 84px;
  object-fit: contain;
}

.navs {
  display: flex;
  flex-direction: column;
  align-items: center;
  row-gap: 10px;
}

.navs ul {
  display: flex;
  align-items: center;
  gap: 10px;
  margin: 0;
}

.navs ul li a {
  padding: 8px 20px;
  cursor: pointer;
  font-size: 16px;
  font-weight: 400;
}

.active {
  color: #182d9a !important;
  border-bottom: 2px solid #182d9a !important;
}

.burger {
  position: relative;
  width: 40px;
  height: 30px;
  background: transparent;
  cursor: pointer;
  display: none;
}

.burger input {
  display: none;
}

.burger span {
  display: block;
  position: absolute;
  height: 3px;
  width: 100%;
  background: #000 !important;
  border-radius: 9px;
  opacity: 1;
  left: 0;
  transform: rotate(0deg);
  transition: 0.25s ease-in-out;
}

.burger span:nth-of-type(1) {
  top: 0px;
  transform-origin: left center;
}

.burger span:nth-of-type(2) {
  top: 50%;
  transform: translateY(-50%);
  transform-origin: left center;
}

.burger span:nth-of-type(3) {
  top: 100%;
  transform-origin: left center;
  transform: translateY(-50%);
}

.burger input:checked ~ span:nth-of-type(1) {
  top: 0;
  left: 5px;
  transform: rotate(45deg);
}

.burger input:checked ~ span:nth-of-type(2) {
  width: 0%;
  opacity: 0;
}

.burger input:checked ~ span:nth-of-type(3) {
  top: 28px;
  left: 5px;
  transform: rotate(-405deg);
}

@media (max-width: 1024px) {
  .top-header {
    max-width: 1024px;
    width: 100%;
  }
  .header .container {
    max-width: 960px;
    width: 100%;
    margin: 0 auto;
  }
  .logo img {
    width: 130px;
  }
}

@media (max-width: 992px) {
  .header .container {
    max-width: 960px;
    padding: 0px 15px;
  }
  .navs {
    display: none;
  }
  .burger {
    display: block;
  }
  .nav-mobile {
    padding: 10px 30px;
    display: block;
    position: fixed;
    overflow: hidden;
    top: 100px;
    right: 0;
    left: 0;
    bottom: 0;
    background: #f2f2f2;
    transition: 0.3s;
    z-index: 999;
    transform: translateX(0);
  }

  .navs.nav-mobile {
    display: flex;
    flex-direction: column;
  }

  .navs.nav-mobile ul {
    display: flex;
    flex-direction: column;
    gap: 30px;
    align-items: flex-start;
    position: absolute;
    top: 15px;
    right: 15px;
    bottom: 15px;
    left: 15px;
    padding: 20px;
    border-radius: 10px;
    background-color: #f2f2f2;
    overflow-y: auto;
    transition: 0.3s;
  }

  .navs.nav-mobile ul li {
    display: block;
  }

  .burger {
    width: 30px;
    height: 20px;
  }
  .burger span {
    display: block;
    position: absolute;
    height: 3px;
    width: 100%;
    background: #fff;
    border-radius: 9px;
    opacity: 1;
    left: 0;
    transform: rotate(0deg);
    transition: 0.25s ease-in-out;
  }
  .burger span:nth-of-type(1) {
    top: 0px;
    transform-origin: left center;
  }

  .burger span:nth-of-type(2) {
    top: 50%;
    transform: translateY(-50%);
    transform-origin: left center;
  }

  .burger span:nth-of-type(3) {
    top: 100%;
    transform-origin: left center;
    transform: translateY(-50%);
  }

  .burger input:checked ~ span:nth-of-type(1) {
    top: 0;
    left: 10px;
    transform: rotate(47deg);
  }

  .burger input:checked ~ span:nth-of-type(2) {
    width: 0%;
    opacity: 0;
  }

  .burger input:checked ~ span:nth-of-type(3) {
    top: 22px;
    left: 10px;
    width: 100%;
    transform: rotate(-406deg);
  }
}

@media (max-width: 768px) {
  .header {
    padding: 20px 0;
  }
  .header .container {
    max-width: 720px;
  }
  .valuta__content strong {
    font-size: 12px;
    font-weight: 500;
    color: #455a64 !important;
  }

  .valuta__content span {
    color: #455a64 !important;
    font-size: 12px;
    font-weight: 400;
  }
  .navs {
    display: none;
  }
  .burger {
    display: block;
  }
  .nav-mobile {
    padding: 10px 30px;
    display: block;
    position: fixed;
    overflow: hidden;
    top: 100px;
    right: 0;
    left: 0;
    bottom: 0;
    background: #f2f2f2;
    transition: 0.3s;
    z-index: 999;
    transform: translateX(0);
  }

  .navs.nav-mobile {
    display: flex;
    flex-direction: column;
  }

  .navs.nav-mobile ul {
    display: flex;
    flex-direction: column;
    gap: 30px;
    align-items: flex-start;
    position: absolute;
    top: 15px;
    right: 15px;
    bottom: 15px;
    left: 15px;
    padding: 20px;
    border-radius: 10px;
    background-color: #f2f2f2;
    overflow-y: auto;
    transition: 0.3s;
  }

  .navs.nav-mobile ul li {
    display: block;
  }

  .burger {
    width: 30px;
    height: 20px;
  }
  .burger span {
    display: block;
    position: absolute;
    height: 3px;
    width: 100%;
    background: #fff;
    border-radius: 9px;
    opacity: 1;
    left: 0;
    transform: rotate(0deg);
    transition: 0.25s ease-in-out;
  }
  .burger span:nth-of-type(1) {
    top: 0px;
    transform-origin: left center;
  }

  .burger span:nth-of-type(2) {
    top: 50%;
    transform: translateY(-50%);
    transform-origin: left center;
  }

  .burger span:nth-of-type(3) {
    top: 100%;
    transform-origin: left center;
    transform: translateY(-50%);
  }

  .burger input:checked ~ span:nth-of-type(1) {
    top: 0;
    left: 10px;
    transform: rotate(47deg);
  }

  .burger input:checked ~ span:nth-of-type(2) {
    width: 0%;
    opacity: 0;
  }

  .burger input:checked ~ span:nth-of-type(3) {
    top: 22px;
    left: 10px;
    width: 100%;
    transform: rotate(-406deg);
  }
}

@media (max-width: 460px) {
  .header {
    display: flex;
    align-items: center;
    justify-content: center;
    max-width: 460px;
    width: 100%;
    padding: 10px 0;
  }
  .header .container {
    padding: 0 20px;
  }
  .navs {
    display: none;
  }
  .burger span {
    background: #000 !important;
  }
  .nav-mobile {
    width: 100%;
    margin: 0 auto;
    box-sizing: border-box;
    padding: 10px 30px;
    display: block;
    position: fixed;
    overflow: hidden;
    top: 100px;
    right: 0;
    left: 0;
    bottom: 0;
    background: #f2f2f2;
    transition: 0.3s;
    z-index: 999;
    transform: translateX(0);
  }

  .navs.nav-mobile ul {
    display: flex;
    flex-direction: column;
    gap: 30px;
    position: absolute;
    top: 15px;
    right: 15px;
    bottom: 15px;
    left: 15px;
    padding: 10px 10px;
    border-radius: 10px;
    background-color: #f2f2f2;
    overflow-y: auto;
    transition: 0.3s;
  }

  .navs.nav-mobile ul {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 20px;
  }

  .navs.nav-mobile ul li {
    display: block;
  }
}

@media (max-width: 435px) {
  .header {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 10px 0;
  }
  .header .container {
    padding: 0 20px;
  }
  .navs {
    display: none;
  }
  .burger span {
    background: #000 !important;
  }
  .nav-mobile {
    width: 400px;
    margin: 0 auto;
    box-sizing: border-box;
    padding: 10px 30px;
    display: block;
    position: fixed;
    overflow: hidden;
    top: 100px;
    right: 0;
    left: 0;
    bottom: 0;
    background: #f2f2f2;
    transition: 0.3s;
    z-index: 999;
    transform: translateX(0);
  }

  .navs.nav-mobile ul {
    display: flex;
    flex-direction: column;
    gap: 30px;
    position: absolute;
    top: 15px;
    right: 15px;
    bottom: 15px;
    left: 15px;
    padding: 10px 10px;
    border-radius: 10px;
    background-color: #f2f2f2;
    overflow-y: auto;
    transition: 0.3s;
  }

  .navs.nav-mobile ul {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 20px;
  }

  .navs.nav-mobile ul li {
    display: block;
  }
}

@media (max-width: 375px) {
  .header {
    max-width: 375px;
    width: 100%;
    /* padding: 60px 15px; */
  }
  .header .container {
    max-width: 320px;
    width: 100%;
  }
  .header-scrolled {
    max-width: 375px;
    width: 100%;
  }
  .navs {
    display: none;
  }
  .nav-mobile {
    width: 375px;
    margin: 0 auto;
    box-sizing: border-box;
    padding: 10px 30px;
    display: block;
    position: fixed;
    overflow: hidden;
    top: 50px;
    right: 0;
    left: 0;
    bottom: 0;
    background: #f2f2f2;
    transition: 0.3s;
    z-index: 999;
    transform: translateX(0);
  }

  .navs.nav-mobile ul {
    display: flex;
    flex-direction: column;
    gap: 30px;
    position: absolute;
    top: 15px;
    right: 15px;
    bottom: 15px;
    left: 15px;
    padding: 10px 10px;
    border-radius: 10px;
    background-color: #f2f2f2;
    overflow-y: auto;
    transition: 0.3s;
  }

  .navs.nav-mobile ul {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 20px;
  }

  .navs.nav-mobile ul li {
    display: block;
  }
}
