.check-modal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.6);
  display: flex;
  align-items: center;
  justify-content: center;
  opacity: 0;
  visibility: hidden;
  transition: opacity 0.3s ease, visibility 0.3s ease;
  z-index: 1000;
}

.check-modal.active {
  opacity: 1;
  visibility: visible;
}

.modal-container {
  position: relative;
  width: 90%;
  max-width: 500px;
  height: 500px;
  border-radius: 10px;
  background-color: white;
  padding: 20px;
  box-shadow: 0 4px 15px rgba(0, 0, 0, 0.3);
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
}

.position {
  position: absolute;
  top: 20px;
  right: 20px;
  cursor: pointer;
}

.position svg {
  width: 30px;
  height: 30px;
  fill: #333;
}

@media (max-width: 768px){
  .modal-container {
    max-height: 350px;
    min-height: 300px;
  }
}