.partner {
  margin-top: 70px;
}

.partner .container h2 {
  text-align: center;
  text-wrap: balance;
}

.partner-box {
  display: flex;
  flex-wrap: wrap;
  margin-top: 40px;
}

.partner-card {
  width: 300px;
  height: 370px;
  margin: 0 auto;
  border: 1px solid rgba(224, 224, 224, 1);
  border-radius: 33px;
  padding: 10px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  transform: scale(0.9);
  transition: transform 0.3s ease;
}

.partner-card:last-child {
  width: 280px;
}

.partner-card:nth-last-child(2) {
  width: 280px;
}

.partner-card:nth-last-child(3) {
  width: 280px;
}

.partner-card:nth-last-child(4) {
  width: 280px;
}

.partner-card p {
  text-align: center;
  text-wrap: balance;
}

.partner-card:hover {
  transform: scale(1);
}

.partner-card-image {
  width: 250px;
  height: 300px;
}

.partner-card-image img {
  width: 100%;
  height: 100%;
  object-fit: contain;
}

@media (max-width: 1024px) {
  .partner {
    max-width: 1024px;
    width: 100%;
  }

  .partner .container {
    max-width: 960px;
    width: 100%;
  }

  .partner-box {
    display: flex;
    flex-wrap: wrap;
    row-gap: 30px;
  }

  .partner-card {
    transform: none;
  }
  .partner-card:last-child {
    width: 290px;
  }

  .partner-card:nth-last-child(2) {
    width: 290px;
  }

  .partner-card:nth-last-child(3) {
    width: 290px;
  }

  .partner-card:nth-last-child(4) {
    width: 290px;
  }
}

@media (max-width: 992px) {
  .partner {
    max-width: 992px;
    width: 100%;
  }

  .partner .container {
    max-width: 960px;
    width: 100%;
  }
}

@media (max-width: 768px) {
  .partner {
    max-width: 768px;
    width: 100%;
  }

  .partner .container {
    max-width: 720px;
    width: 100%;
  }

  .partner-box {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
  }
}

@media (max-width: 476px) {
  .partner {
    max-width: 476px;
    width: 100%;
  }

  .partner .container {
    max-width: 425px;
    width: 100%;
  }

  .partner-box {
    display: grid;
    grid-template-columns: repeat(1, 1fr);
  }
}

@media (max-width: 460px) {
  .partner {
    max-width: 460px;
    width: 100%;
  }

  .partner .container {
    max-width: 375px;
    width: 100%;
  }

  .partner-box {
    display: grid;
    grid-template-columns: repeat(1, 1fr);
  }
}

@media (max-width: 425px) {
  .partner {
    max-width: 425px;
    width: 100%;
  }

  .partner .container {
    max-width: 375px;
    width: 100%;
  }

  .partner-box {
    display: grid;
    grid-template-columns: repeat(1, 1fr);
  }
}

@media (max-width: 375px) {
  .partner {
    max-width: 375px;
    width: 100%;
  }

  .partner .container {
    max-width: 320px;
    width: 100%;
  }

  .partner-box {
    display: grid;
    grid-template-columns: repeat(1, 1fr);
  }
}

