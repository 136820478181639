.banner-top {
  padding-top: 45px;
  margin-top: 80px;
}

.banner-container {
  position: relative;
  width: 100%;
  height: 600px;
}

.carousel-item {
  height: 600px;
}

.carousel-image {
  width: 100% !important;
  height: 100% !important;
  object-fit: cover !important;
}

.link-btn {
  padding: 10px 20px;
  background-color: #182d9a;
  border-radius: 30px;
  color: #fff !important;
  font-size: 16px;
  font-weight: 500;
  cursor: pointer;
}
.carousel-indicators {
  display: none;
}

.loading-placeholder {
  width: 100%;
  min-height: 500px;
  background-color: rgb(172, 166, 166);
  opacity: 0.5;
}

@media (max-width: 1024px) {
  .banner-container {
    height: 600px;
  }
  .carousel-item {
    height: 600px;
  }
}

@media (max-width: 992px) {
  .banner-container {
    height: 600px;
  }
  .carousel-item {
    height: 600px;
  }
}

@media (max-width: 768px) {
  .banner-container {
    height: 400px;
  }
  .carousel-item {
    height: 400px;
  }
}

@media (max-width: 425px) {
  .banner-container {
    height: 200px;
  }
  .carousel-item {
    height: 200px;
  }
}
