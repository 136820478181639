.footer {
  background: #bbc5fa;
  padding: 30px 0;
}

.footer .container {
  display: flex;
  align-items: start;
  justify-content: space-between;
}

.footer-logo {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.logo-color {
  color: #37517e;
}

.footer-logo .logo {
  margin-bottom: 10px;
}

.footer-logo .footer-address {
  margin-bottom: 20px;
  font-size: 14px;
}

.footer-logo .link {
  margin-bottom: 10px;
  font-size: 14px;
  color: #000;
  display: flex;
  align-items: center;
  gap: 10px;
}

.footer-logo p strong {
  font-size: 15px;
}

.footer-menu {
  display: flex;
  flex-direction: column;
  gap: 16px;
}

.footer-menu .footer-link {
  font-size: 16px;
}

.footer-menu .menu-link {
  display: flex;
  flex-direction: column;
  gap: 12px;
}

.footer-menu .menu-link a {
  color: black;
  font-size: 14px;
  transition: all 0.3s ease;
}

.footer-network .our-network {
  margin-bottom: 20px;
}

.footer-icons {
  display: flex;
  align-items: center;
  gap: 30px;
}

.footer-icons .icon {
  width: 36px;
  height: 36px;
  padding: 9px 9px;
  box-sizing: border-box;
  border-radius: 50%;
  cursor: pointer;
}

.footer-icons .icon .icon-back {
  color: rgba(58, 156, 111, 1);
  font-size: 30px;
}

.network-fixed {
  position: fixed;
  right: 50px;
  bottom: 20px;
  transition: transform 0.3s ease;
  cursor: pointer;
}

.fixed-base {
  width: 100px;
  cursor: pointer;
}

.fixed-base img {
  width: 100%;
}

.fixed-content {
  position: absolute;
  top: -200px;
  left: 10px;
  opacity: 0;
  transform: translateY(100%);
  transition: transform 0.5s ease-in, opacity 0.5s ease-in;
  cursor: pointer;
}

.fixed-content.active {
  transform: translateY(0%);
  opacity: 1;
  border: none;
}

.fixed-icons {
  display: flex;
  flex-direction: column;
  width: 100px;
  gap: 30px;
  align-items: center;
  transition: opacity 10s ease;
}

@keyframes slideInRight {
  0% {
    right: -100%;
    opacity: 0;
  }
  100% {
    right: 0;
    opacity: 1;
  }
}

@keyframes slideOutRight {
  0% {
    right: 0;
    opacity: 1;
  }
  100% {
    right: -100%;
    opacity: 0;
  }
}

.ad-banner {
  position: fixed;
  bottom: 0;
  right: -100%;
  background-color: rgba(187, 197, 250, 0.8);
  width: 100%;
  height: 420px;
  z-index: 1000;
  padding: 20px 0;
}

.ad-banner.show {
  animation: slideInRight 1s ease forwards;
}

.ad-banner.hide {
  animation: slideOutRight 1s ease forwards;
}

.ad-banner-first {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
}

.ad-banner-image {
  width: 1100px;
  height: 400px;
}

.ad-banner-image img {
  width: 100%;
  height: 100%;
}

.ad-banner-text {
  display: flex;
  flex-wrap: wrap;
  padding: 20px;
  gap: 20px;
}

.ad-banner p {
  text-align: center;
  font-size: 20px;
}

.ad-banner-text input {
  background: transparent;
  height: 50px;
  border: none;
  outline: none;
  border-bottom: 1px solid #fff;
  padding: 0 20px;
  color: #fff !important;
  font-size: 20px;
}

.ad-banner-text input::placeholder {
  color: #fff !important;
}

.ad-banner-text .name {
  flex: 1 0 120px;
}

.ad-banner-text .phone-number {
  flex: 1 0 120px;
}

.ad-banner-text .submit {
  flex: 1 0 20px;
  background-color: #fff;
  color: #3a9c6f;
  font-size: 20px;
  font-weight: 500;
  border-radius: 50px;
}

.close-position {
  padding: 0 20px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: end;
}

.close-banner {
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #fff;
  width: 200px;
  font-size: 20px;
  font-weight: 500;
  border-radius: 50px;
}

@media (max-width: 1024px) {
  .footer {
    max-width: 1024px;
    width: 100%;
  }
  .footer .container {
    max-width: 960px;
    width: 100%;
  }
  .network-fixed {
    max-width: 960px;
  }
  .fixed-base {
    bottom: 0;
    right: 0;
    position: absolute;
  }
  .fixed-content {
    position: absolute;
    left: -76px;
    opacity: 0;
    top: -300px;
    transform: translateY(65%);
  }
  .ad-banner {
    max-width: 1024px;
    width: 100%;
    padding: 20px;
  }
  .ad-banner-text p {
    font-size: 18px;
  }
}

@media (max-width: 992px) {
  .footer {
    max-width: 992px;
    width: 100%;
  }
  .fixed-content {
    max-width: 960px;
    width: 100%;
    position: absolute;
    left: -75px;
    top: -250px;
  }
  .fixed-content {
    position: absolute;
    left: -76px;
    opacity: 0;
    top: -300px;
    transform: translateY(65%);
  }
  .ad-banner {
    max-width: 992px;
    width: 100%;
  }
}

@media (max-width: 768px) {
  .footer {
    max-width: 768px;
    width: 100%;
  }
  .footer .container {
    width: 700px;
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 20px;
    padding: 0 20px;
  }
  .fixed-content {
    position: absolute;
    left: -75px;
    top: -250px;
  }
  .fixed-content {
    position: absolute;
    left: -76px;
    opacity: 0;
    top: -300px;
    transform: translateY(65%);
  }
  .ad-banner {
    max-width: 768px;
    width: 100%;
  }
  .ad-banner-image {
    width: 700px;
    height: 200px;
  }
}

@media (max-width: 435px) {
  .footer {
    max-width: 435px;
    width: 100%;
  }
  .footer .container {
    max-width: 375px;
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 20px;
  }
  .network-fixed {
    right: 20px;
  }
  .fixed-base {
    width: 70px;
  }
  .fixed-content {
    position: absolute;
    left: -60px;
    top: -200px;
  }
  .fixed-content {
    position: absolute;
    left: -62px;
    opacity: 0;
    top: -270px;
    transform: translateY(65%);
  }
  .ad-banner {
    max-width: 425px;
    width: 100%;
    height: 450px;
  }
  .ad-banner-image {
    display: none;
  }
}

@media (max-width: 375px) {
  .footer {
    max-width: 375px;
    width: 100%;
  }
  .footer .container {
    max-width: 320px;
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 20px;
  }
  .fixed-content {
    position: absolute;
    left: -30px;
    top: -200px;
  }
  .fixed-content {
    position: absolute;
    left: -62px;
    opacity: 0;
    top: -268px;
    transform: translateY(65%);
  }
  .ad-banner {
    max-width: 375px;
    width: 100%;
  }
}
