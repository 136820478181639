.brand {
  margin-top: 50px;
  margin-bottom: 50px;
}

.brand .container h2 {
  text-align: center;
  margin-top: 50px;
  margin-bottom: 50px;
}

.brand-box {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  row-gap: 30px;
}

.brand-card {
  width: 200px;
  height: 80px;
}

.brand-card img {
  width: 100%;
  height: 100%;
  object-fit: contain;
}

@media (max-width: 1024px) {
  .brand {
    max-width: 1024px;
    width: 100%;
  }
  .brand .container {
    max-width: 960px;
    width: 100%;
  }
}

@media (max-width: 992px) {
  .brand {
    max-width: 992px;
    width: 100%;
  }
  .brand .container {
    max-width: 960px;
    width: 100%;
  }
}

@media (max-width: 768px) {
  .brand {
    max-width: 768px;
    width: 100%;
  }
  .brand .container {
    max-width: 720px;
    width: 100%;
  }
}

@media (max-width: 425px) {
  .brand {
    max-width: 425px;
    width: 100%;
  }
  .brand .container {
    max-width: 375px;
    width: 100%;
  }
  .brand-box {
    display: flex;
    align-items: center;
    justify-content: center;
  }
}

@media (max-width: 375px) {
  .brand {
    max-width: 375px;
    width: 100%;
  }
  .brand .container {
    max-width: 320px;
    width: 100%;
  }
}
