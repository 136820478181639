.popup-container {
  position: absolute;
  top: 20%;
  left: 50%;
  transform: translate(-50%, -100%);
  width: 400px;
  height: 500px;
  background: #fff;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  -webkit-box-shadow: 8px 8px 5px -6px rgba(0, 0, 0, 0.75);
  -moz-box-shadow: 8px 8px 5px -6px rgba(0, 0, 0, 0.75);
  box-shadow: 8px 8px 5px -6px rgba(0, 0, 0, 0.75);
  position: relative;
}

.popup-content {
  margin-top: 40px;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 10px 10px 0px 10px;
}

.popup-content h2 {
  margin-bottom: 30px;
}

.popup-content p {
  text-align: center;
  text-wrap: balance;
  margin-bottom: 30px;
}

.popup-container input {
  width: 327px;
  height: 25px;
  border: none;
  border-bottom: 1px solid black;
  outline: none;
  margin-bottom: 30px;
}

.popup-container input:last-child {
  margin-top: 15px;
}

.check {
  display: flex;
  align-items: center;
  gap: 30px;
}

.check input {
  width: 20px;
  height: 20px;
}

.check p {
  text-align: start;
  font-size: 12px !important;
  line-height: 15px !important;
  width: 300px;
}

.popup-container button {
  width: 100% !important;
  border-radius: 0 !important;
}

.popup-position {
  position: absolute;
  top: 20px;
  right: 20px;
  cursor: pointer;
}

