.product-header {
  padding: 30px 0;
  /* margin-top: 100px; */
}

.product-id-content {
  margin-top: 70px;
  margin-bottom: 30px;
}

.get-container {
  display: flex;
  align-items: flex-start;
  gap: 30px;
}

.image-content {
  width: 523px;
  height: 520px;
}

.image-id {
  width: 100%;
  height: 420px;
}

.image-id img {
  width: 100%;
  height: 100%;
  object-fit: contain;
}

.image-content-photos {
  width: 360px;
  display: flex;
  margin: 0 auto;
  align-items: center;
  justify-content: start;
  margin-top: 29px;
  column-gap: 10px;
}

.scroll-images {
  overflow: scroll;
  overflow-y: hidden;
}

.image-content-photos-photo {
  width: 70px;
  height: 70px;
  object-fit: cover;
  cursor: pointer;
}

.get-product-content {
  width: 666px;
}

.content-block {
  margin-top: 30px;
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.block-description {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 15px;
}

.block-description p {
  display: flex;
  align-items: center;
  gap: 10px;
  color: #393939 !important;
  margin: 0;
}

.box-size {
  margin-top: 15px;
  display: flex;
  align-items: center;
  gap: 10px;
}

.card-size {
  width: 37px;
  height: 44px;
  border: 1px solid black;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 8px;
}

.block-size p {
  margin-top: 20px;
}

.tabs-container {
  margin-top: 40px;
  margin-bottom: 100px;
  width: 100%;
  height: 350px;
  position: relative;
}

@media (max-width: 1024px) {
  .product-id-content {
    max-width: 1024px;
  }
  .product-id-content .container {
    max-width: 960px;
    width: 100%;
  }
  .tabs-container {
    width: 100%;
    height: 400px;
  }
}

@media (max-width: 992px) {
  .product-id-content {
    max-width: 992px;
  }
  .product-id-content .container {
    max-width: 960px;
    width: 100%;
  }
  .tabs-container {
    width: 100%;
    height: 400px;
  }
}

@media (max-width: 768px) {
  .product-id-content {
    max-width: 768px;
  }
  .product-id-content .container {
    max-width: 720px;
    width: 100%;
  }
  .block-description p {
    display: flex;
    align-items: flex-start;
  }
  .tabs-container {
    width: 100%;
    height: 450px;
  }
}

@media (max-width: 425px) {
  .product-id-content {
    max-width: 425px;
  }
  .product-id-content .container {
    max-width: 375px;
    width: 100%;
    display: flex;
    flex-direction: column;
  }
  .get-container {
    display: flex;
    flex-direction: column;
  }
  .image-content {
    max-width: 360px;
    width: 100%;
    margin: 0 auto;
  }
  .get-product-content {
    max-width: 360px;
  }
  .block-description p {
    display: flex;
    align-items: flex-start;
  }
  .tabs-container {
    width: 100%;
    height: 550px;
  }
}

@media (max-width: 375px) {
  .product-id-content {
    max-width: 375px;
    width: 100%;
  }
  .product-id-content .container {
    max-width: 320px;
    width: 100%;
    display: flex;
    flex-direction: column;
  }
  .get-container {
    display: flex;
    flex-direction: column;
  }
  .image-content {
    max-width: 300px;
    width: 100%;
    margin: 0 auto;
  }
  .get-product-content {
    max-width: 320px;
  }
  .block-description p {
    display: flex;
    align-items: flex-start;
  }
  .tabs-container {
    width: 100%;
    height: 700px;
  }
}
