.tabs-btns {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 100px;
}

.tabs-btns button {
  color: #393939;
  font-size: 24px;
  font-weight: 600;
  padding: 0px;
}

.active-tabs {
  color: #182d9a !important;
  border-bottom: 2px solid #182d9a;
}

.tabs-content {
  margin-top: 20px;
}

.tabs-content-text {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 10px;
}

.tabs-content-text h2 {
  font-size: 36px;
  color: #393939 !important;
  font-weight: 500;
}

.tabs-content-text p {
  color: #393939 !important;
  font-size: 20px;
  font-weight: 400;
  line-height: 27px;
}

.tabs-content-text p:last-child {
  color: #182d9a !important;
  margin-top: 10px;
}

.tabs-content-text button {
  width: 190px;
  height: 59px;
  background-color: #182d9a;
  color: #fff !important;
  font-size: 18px;
  font-weight: 500;
  border-radius: 30px;
  margin-top: 10px;
}

@media (max-width: 425px) {
  .tabs-btns {
    display: flex;
    align-items: center;
    gap: 10px;
  }
  .tabs-btns button {
    font-size: 16px;
  }
}

@media (max-width: 375px) {
  .tabs-btns {
    display: flex;
    align-items: center;
    gap: 15px;
  }
  .tabs-btns button {
    font-size: 16px;
  }
}
