.stock-info {
  margin-top: 30px;
  margin-bottom: 30px;
}

.stock-info__container h2 {
  text-align: center;
  font-size: 24px;
  font-weight: 600;
  text-wrap: balance;
  margin-bottom: 20px;
}

.stock-info__container h3 {
  text-align: center;
  font-size: 22px;
  font-weight: 600;
  text-wrap: balance;
  margin-bottom: 20px;
}

.stock-info__desc {
  font-size: 20px;
}

.stock-info__container ul {
  margin-top: 20px;
  margin-bottom: 20px;
}

.stock-info__container ul li {
  list-style-type: disc;
  margin: 10px 0;
  font-size: 18px;
}

.stock-info__container ul li span {
  font-weight: 400;
  margin-left: 5px;
}

.stock-info__container p {
  font-size: 18px;
}

@media (max-width: 1024px) {
  .stock-info {
    max-width: 1024px;
    width: 100%;
  }
  .stock-info__container {
    max-width: 960px;
    width: 100%;
    padding: 0 20px;
  }
}

@media (max-width: 992px) {
  .stock-info {
    max-width: 992px;
    width: 100%;
  }
  .stock-info__container {
    max-width: 960px;
    width: 100%;
  }
}

@media (max-width: 768px) {
  .stock-info {
    max-width: 768px;
    width: 100%;
  }
  .stock-info__container {
    max-width: 720px;
    width: 100%;
  }
}

@media (max-width: 425px) {
  .stock-info {
    max-width: 425px;
    width: 100%;
  }
  .stock-info__container {
    max-width: 375px;
    width: 100%;
  }
}

@media (max-width: 375px) {
  .stock-info {
    max-width: 375px;
    width: 100%;
  }
  .stock-info__container {
    max-width: 320px;
    width: 100%;
  }
}
