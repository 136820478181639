.product {
  width: 100%;
  height: auto;
  margin-bottom: 30px;
}

.product-title {
  text-align: center;
  font-size: 32px;
  font-weight: 700;
  color: #37517e;
  margin-bottom: 30px;
}

.product-text {
  text-align: center;
  font-size: 16px;
  color: #444444;
}

.product-box {
  margin-top: 30px;
}

.product-box-card {
  margin-top: 30px;
  max-width: 1200px;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  gap: 10px;
}

.product-box {
  display: flex;
  flex-wrap: wrap;
  align-items: flex-start;
  gap: 10px;
}

.card-item {
  width: 280px;
  height: 370px;
  position: relative;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  text-align: center;
}

.image-container {
  width: 100%;
  height: 300px;
}

.image-container img {
  width: 100%;
  height: 100%;
  object-fit: contain;
  transition: transform 0.5s ease;
}

.card-item-content {
  transition: transform 0.5s ease;
}

.card-content h2 {
  font-size: 20px;
}

.card-item:hover img {
  transform: scale(1.1);
}

@media (max-width: 1024px) {
  .product {
    max-width: 960px;
    margin: 0 auto;
  }
  .categories ul {
    display: flex;
    align-items: center;
    gap: 20px;
  }
  .categories ul li {
    text-align: center;
  }
  .box {
    max-width: 960px;
    display: flex;
    flex-wrap: wrap;
    gap: 25px;
  }
  .card-item {
    width: 295px;
    height: 430px;
    position: relative;
    overflow: hidden;
  }
  .card-item-content {
    transition: transform 0.5s ease;
  }

  .card-item:hover img {
    transform: scale(1);
  }
  .image-container {
    width: 295px;
    height: 373px;
  }
  .image-container img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
}

@media (max-width: 992px) {
  .product {
    max-width: 960px;
    margin: 0 auto;
  }
  .categories ul {
    display: flex;
    align-items: center;
    gap: 20px;
  }
  .categories ul li {
    text-align: center;
  }
  .box {
    max-width: 960px;
    display: flex;
    flex-wrap: wrap;
    gap: 25px;
  }
  .card-item {
    width: 295px;
    height: 430px;
    position: relative;
    overflow: hidden;
  }
  .image-container {
    width: 295px;
    height: 373px;
  }
  .image-container img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
}

@media (max-width: 768px) {
  .product {
    max-width: 768px;
    width: 100%;
    margin-top: 50px;
  }
  .product .container {
    max-width: 720px;
    width: 100%;
  }
}

@media (max-width: 476px) {
  .product {
    max-width: 420px;
    margin: 0 auto;
  }
  .categories ul {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
  }
  .categories ul li {
    display: flex;
    width: 100%;
  }
  .product-box-card {
    max-width: 350px;
    margin: 0 auto;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
  }
  .box {
    max-width: 350px;
    margin: 0 auto;
    display: flex;
    justify-content: space-between;
  }
  .card-item {
    margin: 0 auto;
  }
}

@media (max-width: 460px) {
  .product {
    max-width: 375px;
    margin: 0 auto;
  }
  .categories ul {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
  }
  .categories ul li {
    display: flex;
    width: 100%;
  }
  .product-box-card {
    max-width: 350px;
    margin: 0 auto;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
  }
  .box {
    max-width: 350px;
    margin: 0 auto;
    display: flex;
    justify-content: space-between;
  }
  .card-item {
    margin: 0 auto;
  }
}

@media (max-width: 435px) {
  .product {
    max-width: 375px;
    margin: 0 auto;
  }
  .categories ul {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
  }
  .categories ul li {
    display: flex;
    width: 100%;
  }
  .product-box-card {
    max-width: 350px;
    margin: 0 auto;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
  }
  .box {
    max-width: 350px;
    margin: 0 auto;
    display: flex;
    justify-content: space-between;
  }
  .card-item {
    margin: 0 auto;
  }
}

@media (max-width: 375px) {
  .product {
    max-width: 320px;
    margin: 0 auto;
  }
  .categories ul {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
  }
  .categories ul li {
    display: flex;
    width: 100%;
  }
  .product-box-card {
    max-width: 300px;
    margin: 0 auto;
  }
  .box {
    max-width: 300px;
    margin: 0 auto;
    display: flex;
    justify-content: space-between;
  }
  .card-item {
    margin: 0 auto;
  }
}
