.commitments {
  margin-top: 50px;
  margin-bottom: 50px;
  background-color: #bbc5fa;
  padding: 50px 0;
}

.commitments__text__first {
  text-align: center;
  font-size: 32px;
  font-weight: 500;
}

.commitments__box {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  flex-wrap: wrap;
}

.commitments__card {
  margin-top: 30px;
  width: 350px;
  text-align: center;
}

.commitments__text {
  font-size: 26px;
}

.commitments__description {
  margin-top: 30px;
  font-size: 16px;
  font-weight: 400;
}

@media (max-width: 1024px) {
  .commitments {
    max-width: 1024px;
    width: 100%;
  }
  .commitments .container {
    max-width: 960px;
    width: 100%;
  }
  .commitments__box {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 50px;
  }
}

@media (max-width: 992px) {
  .commitments {
    max-width: 992px;
    width: 100%;
  }
  .commitments .container {
    max-width: 960px;
    width: 100%;
  }
}

@media (max-width: 768px) {
  .commitments {
    max-width: 768px;
    width: 100%;
  }
  .commitments .container {
    max-width: 720px;
    width: 100%;
    margin: 0 auto;
  }
  .commitments__box {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 0;
  }
}

@media (max-width: 425px) {
  .commitments {
    max-width: 425px;
    width: 100%;
  }
  .commitments .container {
    max-width: 375px;
    width: 100%;
  }
  .commitments__card {
    width: 350px;
    margin: 0 auto;
  }
}

@media (max-width: 375px) {
  .commitments {
    max-width: 375px;
    width: 100%;
  }
  .commitments .container {
    max-width: 320px;
    width: 100%;
  }
  .commitments__box {
    max-width: 320px;
    width: 100%;
    text-wrap: balance;
  }
  .commitments__description {
    max-width: 320px;
    width: 100%;
    text-wrap: balance;
  }
  .commitments__card {
    width: 350px;
    margin: 0 auto;
  }
}
