@import url("https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100..900;1,100..900&display=swap");

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  color: #393939 !important;
  font-family: "Montserrat", sans-serif !important;
}

html,
body {
  min-height: 100vh;
}

a {
  text-decoration: none;
}

ul li {
  list-style: none;
  line-height: normal;
}

button {
  border: none;
  outline: none;
  background: transparent;
  padding: 8px 20px;
  cursor: pointer;
}

.wrapper {
  /* max-width: 1440px; */
  margin: 0 auto;
}

.container {
  max-width: 1200px;
  margin: 0 auto;
}
