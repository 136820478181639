.reviews {
  margin-top: 150px;
  height: 75vh;
}

.video-content {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
  column-gap: 50px;
  row-gap: 30px;
}

.youtube-video-wrapper {
  width: 550px;
  height: 390px;
  position: relative;
  overflow: hidden;
}

.youtube-video-wrapper iframe {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

@media (max-width: 1024px) {
  .reviews {
    max-width: 1024px;
    width: 100%;
    height: 75vh;
  }
  .reviews .container {
    max-width: 960px;
    width: 100%;
  }
  .video-content {
    display: flex;
  }
  .youtube-video-wrapper {
    width: 443px;
    height: 320px;
  }
}

@media (max-width: 992px) {
  .reviews {
    max-width: 992px;
    width: 100%;
    height: 75vh;
  }
  .reviews .container {
    max-width: 960px;
    width: 100%;
  }
}

@media (max-width: 768px) {
  .reviews {
    max-width: 768px;
    width: 100%;
    min-height: 700px;
  }
  .reviews .container {
    max-width: 720px;
    width: 100%;
  }
}

@media (max-width: 425px) {
  .reviews {
    max-width: 425px;
    width: 100%;
  }
  .reviews .container {
    max-width: 375px;
    width: 100%;
  }
}
