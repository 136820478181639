.schema-component {
  margin-top: 100px;
  min-height: auto;
}

.schema-component .container h2 {
  text-align: center;
  text-wrap: balance;
}

.schema-box {
  height: 1300px;
  margin-top: 50px;
}

.schema-container {
  width: 750px;
  height: 159px;
  padding: 10px;
  border-right: 0px;
  border-top: 3px solid #182d9a;
  border-bottom: 3px solid #182d9a;
  border-left: 3px solid #182d9a;
  border-top-right-radius: 84px;
  border-bottom-right-radius: 84px;
  border-top-left-radius: 84px;
  border-bottom-left-radius: 84px;
}

.schema-container:first-child {
  border-top-right-radius: 0px;
  border-bottom-right-radius: 0px;
}

.schema-container:nth-child(2) {
  position: absolute;
  right: 400px;
  border-right: 3px solid #182d9a;
  border-left: 0px;
  border-top-left-radius: 0px;
  border-bottom-left-radius: 0px;
  margin-top: -3px;
}

.schema-container:nth-child(3) {
  margin-top: 153px;
  border-top-right-radius: 0px;
  border-bottom-right-radius: 0px;
}

.schema-container:nth-child(4) {
  position: absolute;
  right: 400px;
  border-right: 3px solid #182d9a;
  border-left: 0px;
  border-top-left-radius: 0px;
  border-bottom-left-radius: 0px;
  margin-top: -3px;
}

.schema-container:nth-child(5) {
  margin-top: 153px;
  border-top-right-radius: 0px;
  border-bottom-right-radius: 0px;
}

.schema-container:nth-child(6) {
  position: absolute;
  right: 400px;
  border-right: 3px solid #182d9a;
  border-left: 0px;
  border-top-left-radius: 0px;
  border-bottom-left-radius: 0px;
  margin-top: -3px;
}

.schema-container:nth-child(7) {
  margin-top: 153px;
  border-top-right-radius: 0px;
  border-bottom-right-radius: 0px;
}

.schema-container:nth-child(8) {
  position: absolute;
  right: 400px;
  border-right: 3px solid #182d9a;
  border-left: 0px;
  border-top-left-radius: 0px;
  border-bottom-left-radius: 0px;
  margin-top: -3px;
}

.schema-card {
  width: 100%;
  height: 100%;
  border: 1px solid #d8d5d5;
  border-radius: 84px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.schema-card-text {
  width: 400px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.schema-card-text h2 {
  text-align: center;
  font-size: 20px;
  font-weight: 600;
}

.schema-card-text p {
  margin: 0;
}

.schema-card-image {
  width: 200px;
  height: 200px;
}

.schema-card-image img {
  width: 100%;
  height: 100%;
}

@media (max-width: 1024px) {
  .schema-component {
    max-width: 1024px;
  }

  .schema-component .container {
    max-width: 960px;
  }
  .schema-container:first-child {
    border-top-right-radius: 0px;
    border-bottom-right-radius: 0px;
  }

  .schema-container:nth-child(2) {
    position: absolute;
    right: 35px;
    border-right: 3px solid #182d9a;
    border-left: 0px;
    border-top-left-radius: 0px;
    border-bottom-left-radius: 0px;
    margin-top: -3px;
  }

  .schema-container:nth-child(3) {
    margin-top: 153px;
    border-top-right-radius: 0px;
    border-bottom-right-radius: 0px;
  }

  .schema-container:nth-child(4) {
    position: absolute;
    right: 35px;
    border-right: 3px solid #182d9a;
    border-left: 0px;
    border-top-left-radius: 0px;
    border-bottom-left-radius: 0px;
    margin-top: -3px;
  }

  .schema-container:nth-child(5) {
    margin-top: 153px;
    border-top-right-radius: 0px;
    border-bottom-right-radius: 0px;
  }

  .schema-container:nth-child(6) {
    position: absolute;
    right: 35px;
    border-right: 3px solid #182d9a;
    border-left: 0px;
    border-top-left-radius: 0px;
    border-bottom-left-radius: 0px;
    margin-top: -3px;
  }

  .schema-container:nth-child(7) {
    margin-top: 153px;
    border-top-right-radius: 0px;
    border-bottom-right-radius: 0px;
  }

  .schema-container:nth-child(8) {
    position: absolute;
    right: 35px;
    border-right: 3px solid #182d9a;
    border-left: 0px;
    border-top-left-radius: 0px;
    border-bottom-left-radius: 0px;
    margin-top: -3px;
  }
  .schema-card-image {
    width: 100px;
    height: 100px;
  }
  .schema-card-image img {
    width: 100%;
    height: 100%;
    object-fit: contain;
  }
}

@media (max-width: 992px) {
  .schema-component {
    max-width: 1024px;
  }

  .schema-component .container {
    max-width: 960px;
  }
  .schema-container:first-child {
    border-top-right-radius: 0px;
    border-bottom-right-radius: 0px;
  }

  .schema-container:nth-child(2) {
    position: absolute;
    right: 35px;
    border-right: 3px solid #182d9a;
    border-left: 0px;
    border-top-left-radius: 0px;
    border-bottom-left-radius: 0px;
    margin-top: -3px;
  }

  .schema-container:nth-child(3) {
    margin-top: 153px;
    border-top-right-radius: 0px;
    border-bottom-right-radius: 0px;
  }

  .schema-container:nth-child(4) {
    position: absolute;
    right: 35px;
    border-right: 3px solid #182d9a;
    border-left: 0px;
    border-top-left-radius: 0px;
    border-bottom-left-radius: 0px;
    margin-top: -3px;
  }

  .schema-container:nth-child(5) {
    margin-top: 153px;
    border-top-right-radius: 0px;
    border-bottom-right-radius: 0px;
  }

  .schema-container:nth-child(6) {
    position: absolute;
    right: 35px;
    border-right: 3px solid #182d9a;
    border-left: 0px;
    border-top-left-radius: 0px;
    border-bottom-left-radius: 0px;
    margin-top: -3px;
  }

  .schema-container:nth-child(7) {
    margin-top: 153px;
    border-top-right-radius: 0px;
    border-bottom-right-radius: 0px;
  }

  .schema-container:nth-child(8) {
    position: absolute;
    right: 35px;
    border-right: 3px solid #182d9a;
    border-left: 0px;
    border-top-left-radius: 0px;
    border-bottom-left-radius: 0px;
    margin-top: -3px;
  }
}

@media (max-width: 768px) {
  .schema-component {
    max-width: 768px;
  }

  .schema-component .container {
    max-width: 720px;
    height: auto;
  }

  .schema-box {
    height: 2000px;
  }

  .schema-container {
    width: 349px;
    height: 250px;
    padding: 10px;
    border-right: 0px;
    border-top: 0px solid #182d9a;
    border-bottom: 0px solid #182d9a;
    border-left: 3px solid #182d9a;
    border-top-right-radius: 84px;
    border-bottom-right-radius: 84px;
    border-top-left-radius: 84px;
    border-bottom-left-radius: 84px;
    margin: 0 auto;
  }

  .schema-container:first-child {
    border-top-right-radius: 0px;
    border-bottom-right-radius: 0px;
  }

  .schema-container:nth-child(2) {
    position: absolute;
    right: 200px;
    border-right: 3px solid #182d9a;
    border-left: 0px;
    border-top-left-radius: 0px;
    border-bottom-left-radius: 0px;
  }

  .schema-container:nth-child(3) {
    margin-top: 244px;
    border-top-right-radius: 0px;
    border-bottom-right-radius: 0px;
  }

  .schema-container:nth-child(4) {
    position: absolute;
    right: 200px;
    border-right: 3px solid #182d9a;
    border-left: 0px;
    border-top-left-radius: 0px;
    border-bottom-left-radius: 0px;
  }

  .schema-container:nth-child(5) {
    margin-top: 244px;
    border-top-right-radius: 0px;
    border-bottom-right-radius: 0px;
  }

  .schema-container:nth-child(6) {
    position: absolute;
    right: 200px;
    border-right: 3px solid #182d9a;
    border-left: 0px;
    border-top-left-radius: 0px;
    border-bottom-left-radius: 0px;
  }

  .schema-container:nth-child(7) {
    margin-top: 244px;
    border-top-right-radius: 0px;
    border-bottom-right-radius: 0px;
  }

  .schema-container:nth-child(8) {
    position: absolute;
    right: 200px;
    border-right: 3px solid #182d9a;
    border-left: 0px;
    border-top-left-radius: 0px;
    border-bottom-left-radius: 0px;
  }
  .schema-card {
    width: 100%;
    height: 100%;
    border: 1px solid #d8d5d5;
    border-radius: 99px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
  .schema-card-text {
    width: 270px;
    margin: 0 auto !important;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;
  }
  .schema-card-text h2 {
    font-size: 15px;
    text-align: center !important;
  }
  .schema-card-text p {
    font-size: 14px;
    text-wrap: balance;
  }
}

@media (max-width: 476px) {
  .schema-component {
    max-width: 425px;
    width: 100%;
  }

  .schema-box {
    height: 2100px;
  }

  .schema-component .container {
    max-width: 375px;
    width: 100%;
  }

  .schema-container {
    width: 349px;
    height: 260px;
    padding: 10px;
    border-right: 0px;
    border-top: 0px solid #182d9a;
    border-bottom: 0px solid #182d9a;
    border-left: 3px solid #182d9a;
    border-top-right-radius: 84px;
    border-bottom-right-radius: 84px;
    border-top-left-radius: 84px;
    border-bottom-left-radius: 84px;
    margin: 0 auto;
  }

  .schema-container:first-child {
    border-top-right-radius: 0px;
    border-bottom-right-radius: 0px;
  }

  .schema-container:nth-child(2) {
    position: absolute;
    right: 40px;
    border-right: 3px solid #182d9a;
    border-left: 0px;
    border-top-left-radius: 0px;
    border-bottom-left-radius: 0px;
    margin-top: -1px;
  }

  .schema-container:nth-child(3) {
    margin-top: 258px;
    border-top-right-radius: 0px;
    border-bottom-right-radius: 0px;
  }

  .schema-container:nth-child(4) {
    position: absolute;
    right: 40px;
    border-right: 3px solid #182d9a;
    border-left: 0px;
    border-top-left-radius: 0px;
    border-bottom-left-radius: 0px;
    margin-top: -1px;
  }

  .schema-container:nth-child(5) {
    margin-top: 258px;
    border-top-right-radius: 0px;
    border-bottom-right-radius: 0px;
  }

  .schema-container:nth-child(6) {
    position: absolute;
    right: 40px;
    border-right: 3px solid #182d9a;
    border-left: 0px;
    border-top-left-radius: 0px;
    border-bottom-left-radius: 0px;
    margin-top: -1px;
  }

  .schema-container:nth-child(7) {
    margin-top: 258px;
    border-top-right-radius: 0px;
    border-bottom-right-radius: 0px;
  }

  .schema-container:nth-child(8) {
    position: absolute;
    right: 40px;
    border-right: 3px solid #182d9a;
    border-left: 0px;
    border-top-left-radius: 0px;
    border-bottom-left-radius: 0px;
    margin-top: -1px;
  }
  .schema-card {
    width: 100%;
    height: 100%;
    border: 1px solid #d8d5d5;
    border-radius: 99px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 20px;
    gap: 10px !important;
  }
  .schema-card-text {
    width: 270px;
    margin: 0 auto !important;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;
  }
  .schema-card-text h2 {
    font-size: 15px;
    text-align: center !important;
  }
  .schema-card-text p {
    font-size: 14px;
    text-wrap: balance;
  }
}

@media (max-width: 460px) {
  .schema-component {
    max-width: 425px;
    width: 100%;
  }

  .schema-box {
    height: 2100px;
  }

  .schema-component .container {
    max-width: 375px;
    width: 100%;
  }

  .schema-container {
    width: 349px;
    height: 260px;
    padding: 10px;
    border-right: 0px;
    border-top: 0px solid #182d9a;
    border-bottom: 0px solid #182d9a;
    border-left: 3px solid #182d9a;
    border-top-right-radius: 84px;
    border-bottom-right-radius: 84px;
    border-top-left-radius: 84px;
    border-bottom-left-radius: 84px;
    margin: 0 auto;
  }

  .schema-container:first-child {
    border-top-right-radius: 0px;
    border-bottom-right-radius: 0px;
  }

  .schema-container:nth-child(2) {
    position: absolute;
    right: 40px;
    border-right: 3px solid #182d9a;
    border-left: 0px;
    border-top-left-radius: 0px;
    border-bottom-left-radius: 0px;
    margin-top: -1px;
  }

  .schema-container:nth-child(3) {
    margin-top: 258px;
    border-top-right-radius: 0px;
    border-bottom-right-radius: 0px;
  }

  .schema-container:nth-child(4) {
    position: absolute;
    right: 40px;
    border-right: 3px solid #182d9a;
    border-left: 0px;
    border-top-left-radius: 0px;
    border-bottom-left-radius: 0px;
    margin-top: -1px;
  }

  .schema-container:nth-child(5) {
    margin-top: 258px;
    border-top-right-radius: 0px;
    border-bottom-right-radius: 0px;
  }

  .schema-container:nth-child(6) {
    position: absolute;
    right: 40px;
    border-right: 3px solid #182d9a;
    border-left: 0px;
    border-top-left-radius: 0px;
    border-bottom-left-radius: 0px;
    margin-top: -1px;
  }

  .schema-container:nth-child(7) {
    margin-top: 258px;
    border-top-right-radius: 0px;
    border-bottom-right-radius: 0px;
  }

  .schema-container:nth-child(8) {
    position: absolute;
    right: 40px;
    border-right: 3px solid #182d9a;
    border-left: 0px;
    border-top-left-radius: 0px;
    border-bottom-left-radius: 0px;
    margin-top: -1px;
  }
  .schema-card {
    width: 100%;
    height: 100%;
    border: 1px solid #d8d5d5;
    border-radius: 99px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 20px;
    gap: 10px !important;
  }
  .schema-card-text {
    width: 270px;
    margin: 0 auto !important;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;
  }
  .schema-card-text h2 {
    font-size: 15px;
    text-align: center !important;
  }
  .schema-card-text p {
    font-size: 14px;
    text-wrap: balance;
  }
}

@media (max-width: 425px) {
  .schema-component {
    max-width: 425px;
  }

  .schema-box {
    height: 2100px;
  }

  .schema-component .container {
    max-width: 375px;
  }

  .schema-container {
    width: 349px;
    height: 260px;
    padding: 10px;
    border-right: 0px;
    border-top: 0px solid #182d9a;
    border-bottom: 0px solid #182d9a;
    border-left: 3px solid #182d9a;
    border-top-right-radius: 84px;
    border-bottom-right-radius: 84px;
    border-top-left-radius: 84px;
    border-bottom-left-radius: 84px;
    margin: 0 auto;
  }

  .schema-container:first-child {
    border-top-right-radius: 0px;
    border-bottom-right-radius: 0px;
  }

  .schema-container:nth-child(2) {
    position: absolute;
    right: 40px;
    border-right: 3px solid #182d9a;
    border-left: 0px;
    border-top-left-radius: 0px;
    border-bottom-left-radius: 0px;
    margin-top: -1px;
  }

  .schema-container:nth-child(3) {
    margin-top: 258px;
    border-top-right-radius: 0px;
    border-bottom-right-radius: 0px;
  }

  .schema-container:nth-child(4) {
    position: absolute;
    right: 40px;
    border-right: 3px solid #182d9a;
    border-left: 0px;
    border-top-left-radius: 0px;
    border-bottom-left-radius: 0px;
    margin-top: -1px;
  }

  .schema-container:nth-child(5) {
    margin-top: 258px;
    border-top-right-radius: 0px;
    border-bottom-right-radius: 0px;
  }

  .schema-container:nth-child(6) {
    position: absolute;
    right: 40px;
    border-right: 3px solid #182d9a;
    border-left: 0px;
    border-top-left-radius: 0px;
    border-bottom-left-radius: 0px;
    margin-top: -1px;
  }

  .schema-container:nth-child(7) {
    margin-top: 258px;
    border-top-right-radius: 0px;
    border-bottom-right-radius: 0px;
  }

  .schema-container:nth-child(8) {
    position: absolute;
    right: 40px;
    border-right: 3px solid #182d9a;
    border-left: 0px;
    border-top-left-radius: 0px;
    border-bottom-left-radius: 0px;
    margin-top: -1px;
  }
  .schema-card {
    width: 100%;
    height: 100%;
    border: 1px solid #d8d5d5;
    border-radius: 99px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 20px;
    gap: 10px !important;
  }
  .schema-card-text {
    width: 270px;
    margin: 0 auto !important;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;
  }
  .schema-card-text h2 {
    font-size: 15px;
    text-align: center !important;
  }
  .schema-card-text p {
    font-size: 14px;
    text-wrap: balance;
  }
}

@media (max-width: 375px) {
  .schema-component {
    max-width: 375px;
  }

  .schema-box {
    height: 2200px;
  }

  .schema-component .container {
    max-width: 320px;
  }

  .schema-container {
    width: 329px;
    height: 270px;
    padding: 10px;
    border-right: 0px;
    border-top: 0px solid #182d9a;
    border-bottom: 0px solid #182d9a;
    border-left: 3px solid #182d9a;
    border-top-right-radius: 84px;
    border-bottom-right-radius: 84px;
    border-top-left-radius: 84px;
    border-bottom-left-radius: 84px;
    margin: 0 auto;
  }

  .schema-container:first-child {
    border-top-right-radius: 0px;
    border-bottom-right-radius: 0px;
  }

  .schema-container:nth-child(2) {
    position: absolute;
    right: 24px;
    border-right: 3px solid #182d9a;
    border-left: 0px;
    border-top-left-radius: 0px;
    border-bottom-left-radius: 0px;
    margin-top: -1px;
  }

  .schema-container:nth-child(3) {
    margin-top: 268px;
    border-top-right-radius: 0px;
    border-bottom-right-radius: 0px;
  }

  .schema-container:nth-child(4) {
    position: absolute;
    right: 24px;
    border-right: 3px solid #182d9a;
    border-left: 0px;
    border-top-left-radius: 0px;
    border-bottom-left-radius: 0px;
    margin-top: -1px;
  }

  .schema-container:nth-child(5) {
    margin-top: 268px;
    border-top-right-radius: 0px;
    border-bottom-right-radius: 0px;
  }

  .schema-container:nth-child(6) {
    position: absolute;
    right: 24px;
    border-right: 3px solid #182d9a;
    border-left: 0px;
    border-top-left-radius: 0px;
    border-bottom-left-radius: 0px;
    margin-top: -1px;
  }

  .schema-container:nth-child(7) {
    margin-top: 268px;
    border-top-right-radius: 0px;
    border-bottom-right-radius: 0px;
  }

  .schema-container:nth-child(8) {
    position: absolute;
    right: 24px;
    border-right: 3px solid #182d9a;
    border-left: 0px;
    border-top-left-radius: 0px;
    border-bottom-left-radius: 0px;
    margin-top: -1px;
  }
  .schema-card {
    width: 100%;
    height: 100%;
    border: 1px solid #d8d5d5;
    border-radius: 99px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 20px;
    gap: 10px !important;
  }
  .schema-card-text {
    width: 270px;
    margin: 0 auto !important;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;
  }
  .schema-card-text h2 {
    font-size: 15px;
    text-align: center !important;
  }
  .schema-card-text p {
    font-size: 14px;
    text-wrap: balance;
  }
}

@media (max-width: 320px) {
  .schema-component {
    max-width: 320px;
  }

  .schema-component .container {
    max-width: 300px;
  }

  .schema-component .container h2 {
    font-size: 20px;
  }

  .schema-container {
    width: 300px;
    height: 190px;
    padding: 10px;
    border-right: 0px;
    border-top: 0px solid #182d9a;
    border-bottom: 0px solid #182d9a;
    border-left: 3px solid #182d9a;
    border-top-right-radius: 84px;
    border-bottom-right-radius: 84px;
    border-top-left-radius: 84px;
    border-bottom-left-radius: 84px;
    margin: 0 auto;
  }

  .schema-container:first-child {
    border-top-right-radius: 0px;
    border-bottom-right-radius: 0px;
  }

  .schema-container:nth-child(2) {
    position: absolute;
    right: 12px;
    border-right: 3px solid #182d9a;
    border-left: 0px;
    border-top-left-radius: 0px;
    border-bottom-left-radius: 0px;
    margin-top: -1px;
  }

  .schema-container:nth-child(3) {
    margin-top: 188px;
    border-top-right-radius: 0px;
    border-bottom-right-radius: 0px;
  }

  .schema-container:nth-child(4) {
    position: absolute;
    right: 12px;
    border-right: 3px solid #182d9a;
    border-left: 0px;
    border-top-left-radius: 0px;
    border-bottom-left-radius: 0px;
    margin-top: -1px;
  }

  .schema-container:nth-child(5) {
    margin-top: 188px;
    border-top-right-radius: 0px;
    border-bottom-right-radius: 0px;
  }

  .schema-container:nth-child(6) {
    position: absolute;
    right: 12px;
    border-right: 3px solid #182d9a;
    border-left: 0px;
    border-top-left-radius: 0px;
    border-bottom-left-radius: 0px;
    margin-top: -1px;
  }

  .schema-container:nth-child(7) {
    margin-top: 188px;
    border-top-right-radius: 0px;
    border-bottom-right-radius: 0px;
  }

  .schema-container:nth-child(8) {
    position: absolute;
    right: 12px;
    border-right: 3px solid #182d9a;
    border-left: 0px;
    border-top-left-radius: 0px;
    border-bottom-left-radius: 0px;
    margin-top: -1px;
  }
  .schema-card {
    width: 100%;
    height: 100%;
    border: 1px solid #d8d5d5;
    border-radius: 99px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 30px !important;
    box-sizing: border-box;
    gap: 10px !important;
  }
  .schema-card-text {
    width: 200px;
    margin: 0 auto !important;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;
  }
  .schema-card-text h2 {
    font-size: 14px !important;
    text-align: center !important;
  }
  .schema-card-text p {
    font-size: 12px;
    text-wrap: balance;
  }
}
