.order-top {
  margin-top: 100px;
}

.order-container {
  margin-top: 30px;
  background: #182d9a;
  padding: 80px 0;
}

.order-container .container {
  display: flex;
  flex-direction: column;
  gap: 40px;
}

.order-container .container h2 {
  color: #fff !important;
  margin-left: 15px;
}

.form {
  display: flex;
  flex-wrap: wrap;
  padding: 20px;
  gap: 20px;
}

.form input {
  background: transparent;
  height: 50px;
  border: none;
  outline: none;
  border-bottom: 1px solid #fff;
  padding: 0 20px;
  color: #fff !important;
  font-size: 20px;
}

.form input::placeholder {
  color: #fff !important;
}

.name {
  flex: 1 0 120px;
}

.phone-number {
  flex: 1 0 120px;
}

.submit {
  flex: 1 0 20px;
  background-color: #fff;
  color: #3a9c6f;
  font-size: 20px;
  font-weight: 500;
  border-radius: 50px;
}

.submit:disabled {
  background-color: #a1a1a1;
  cursor: not-allowed;
}

.submit:hover:not(:disabled) {
  background-color: #fff;
}

@media (max-width: 1024px) {
  .order-container {
    max-width: 1024px;
  }
  .order-container .container {
    max-width: 960px;
  }
  .order-container .container h2 {
    margin-left: 20px;
  }
}

@media (max-width: 992px) {
  .order-container {
    max-width: 992px;
  }
  .order-container .container {
    max-width: 960px;
  }
  .order-container .container h2 {
    margin-left: 20px;
  }
}

@media (max-width: 768px) {
  .order-container {
    max-width: 768px;
  }
  .order-container .container {
    max-width: 720px;
  }
  .order-container .container h2 {
    margin-left: 20px;
  }
}

@media (max-width: 425px) {
  .order-container {
    max-width: 425px;
  }
  .order-container .container {
    max-width: 375px;
  }
  .order-container .container h2 {
    margin-left: 15px;
  }
}

@media (max-width: 375px) {
  .order-container {
    max-width: 375px;
  }
  .order-container .container {
    max-width: 320px;
  }
  .order-container .container h2 {
    margin-left: 15px;
  }
}

@media (max-width: 320px) {
  .order-container {
    max-width: 320px;
  }
  .order-container .container {
    max-width: 300px;
  }
  .order-container .container h2 {
    margin-left: 20px;
  }
}
